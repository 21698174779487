.App {
    text-align: center;
    --primary: #3DAF7E;
    --primary-hover: #3DAF7E;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #47cc97 !important;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: #47cc97 !important;
}

section .placeholder + .placeholder {
    display: none;
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    font-size: 16px;
    position: sticky;
    top: 0;
    z-index: 10;
}

.App-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.App-worker-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.App-corp {
    padding: 0.5rem;
    margin-bottom: 2rem;
    width: 100%;
    background-color: #222128;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.corp-name {
    font-size: 1.15rem;
    font-weight: 600;
    color: #FCFBFC;
}

.corp-balance {
    font-size: 2.5rem;
    font-weight: 400;
    color: #47cc97;
    margin-top: -0.15rem;
    width: 75%;
}

.corp-balance-pulse {
    animation: pulse 200ms ease-in 0s 1 normal forwards;
}

.negative {
    color: #E26363 !important;
}

.invisible {
    opacity: 0%;
}

.hidden {
    display: none;
}

.news {
    margin: 0 20rem;
    font-size: 0.6rem;
    text-transform: uppercase;
    color: rgba(252, 251, 252, 0.75);
}

.modal {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-transition: 0.5s;
    backdrop-filter: blur(10px);
    overflow: auto;
    transition: all 0.3s linear;
}

.modal-content {
    background-color: #efefef;
    margin: auto;
    padding: 2rem;
    border-radius: 4px;

    max-width: 30rem;

}

.create-new-button {
    margin-bottom: 0;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}