.Item {
    width: 11rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    --primary: #47cc97;
    --primary-hover: #47cc97;

}

.Item-circle {
    width: 6rem;
    height: 6rem;
    position: relative;
}

.hire-button {
    margin: 1rem;
}

.stats {
    font-size: 12px;
}

.avatar {
    width: 5rem;
    position: absolute;
    top: .5rem;
    left: .5rem;
}

.hidden-amount {
    display: none;
}

.animated-amount {
    position: absolute;
    right: 0;
    left: -20%;
    top: 30%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 140%;

    font-size: 1.25rem;
    font-weight: bold;
    color: #63E2A6;

    animation: animation-amount 0.4s ease-out 0s 1 normal forwards;
}

.animated-death {
    animation: animation-death 1500ms ease 0s 1 normal forwards;
}

.question-mark {
    border: #9691b0 dashed 2px;
    border-radius: 50%;
    height: 5rem;
    width: 5rem;

    position: absolute;
    right: 0;
    left: 0;
    top: 0.5rem;
    margin-left: auto;
    margin-right: auto;
}

.negative {
    color: #E26363;
}

.subtext {
    width: 9rem;
    font-size: 0.8rem;
    color: #415462;
}

.type-title {
    display: inline-block;
}

.type-title::first-letter {
    text-transform: uppercase;
}

.upgraded {
    color: #4771cc;
}

.synergy {
    color: #4771cc;
    font-weight: 500;
}

.death-text {
    color: #E26363;
}

.hire-text {
    color: #47cc97;
}

.hire-animation {
    animation: hire-anim 100ms ease 0s 1 normal forwards;
}

@keyframes hire-anim {
    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70% {
        transform: translateX(-3px);
    }

    20%,
    40%,
    60% {
        transform: translateX(3px);
    }

    80% {
        transform: translateX(2px);
    }

    90% {
        transform: translateX(-1px);
    }
}


@keyframes animation-amount {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-80px);
    }
}

@keyframes animation-death {
    0% {
        opacity: 0.4;
        transform: scale(0.1);
    }

    60% {
        opacity: 0.7;
        transform: scale(0.60);
    }

    100% {
        opacity: 0;
        transform: scale(1.0);
    }
}