.ResearchItem {
    width: 11rem;
    --primary: #4771cc;
    --primary-hover: #4771cc;
}

.description-placeholder {
    border: #9691b0 dashed 2px;
    border-radius: 0.3rem;

    font-style: italic;

    margin-bottom: 0.5rem;
    padding: 0.75rem;
    display: flex;
    align-items: center;
    min-height: 14rem;
}

.description {
    background-color: #fff;
    font-style: italic;
    margin-bottom: 0.5rem;
    border-radius: 0.3rem;
    padding: 0.75rem;
    display: flex;
    align-items: center;
    min-height: 14rem;
}